import React, { useState, useEffect } from "react";
import Summary from "./FormSummary";
import { useCreateLoanMutation } from "../../slices/loanApiSlice";

// Function to get the user's IP address
async function getUserIP() {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Failed to fetch IP address:", error);
    return null;
  }
}

const LoanApplicationForm = () => {
  const [formData, setFormData] = useState({
    hearAbout: "",
    otherHearAbout: "", 
    financialSolution: "",
    firstName: "",
    lastName: "",
    email: "",
    dob: "",
    ssn: "",
    phoneHome: "",
    address1: "",
    zipCode: "",
    driversLicense: "",
    driversLicenseState: "",
    requestedAmount: "",
    loanPurpose: "",
    credit: "",
    ownHome: "",
    employerName: "",
    monthsAtCompany: "",
    incomeType: "",
    monthlyIncome: "",
    payDate1: "",
    payFrequency: "Weekly",
    directDeposit: "",
    bankName: "",
    bankAccountType: "Checking",
    bankABA: "",
    bankAccountNumber: "",
    ipAddress: "", 
    tellUsMore: "", // Changed from hardships to tellUsMore
  });


  const [showSummary, setShowSummary] = useState(false);
  const [createLoan, { isLoading, error }] = useCreateLoanMutation();
  const [currentSection, setCurrentSection] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState({});
  const [progress, setProgress] = useState(0);
  const [isOtherSelected, setIsOtherSelected] = useState(false); 
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  
  const validateSection = () => {
    const errors = {};
    // Check required fields for the current section
    if (currentSection === 0) {
      if (!formData.firstName) errors.firstName = "First Name is required.";
      if (!formData.lastName) errors.lastName = "Last Name is required.";
      if (!formData.email) errors.email = "Email is required.";
      if (!formData.dob) errors.dob = "Date of Birth is required.";
      if (!formData.phoneHome) errors.phoneHome = "Phone is required.";
      if (!formData.zipCode) errors.zipCode = "Zip Code is required.";
    } else if (currentSection === 1) {
      if (!formData.incomeType) errors.incomeType = "Income Type is required.";
      if (!formData.monthlyIncome) errors.monthlyIncome = "Monthly Income is required.";
    } else if (currentSection === 2) {
      if (!formData.tellUsMore) errors.tellUsMore = "Please tell us more.";
    }
    return errors;
  };

  const handleNext = () => {
    const errors = validateSection();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return; // Prevent navigation if there are errors
    }
    setFormErrors({});
    setCurrentSection(currentSection + 1);
    setProgress(progress + 33.33);
  };

  const handlePrev = () => {
    setCurrentSection(currentSection - 1);
    setShowSummary(false);
    setProgress(progress - 33.33);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "hearAbout") {
      setIsOtherSelected(value === "Other");
      setFormData({
        ...formData,
        [name]: value,
        otherHearAbout: "",
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedFormData = {
        ...formData,
        hearAbout: isOtherSelected ? formData.otherHearAbout : formData.hearAbout,
    };

    setFormData(updatedFormData);

    try {
        const response = await createLoan(updatedFormData).unwrap();

        if (response.status === "success") {
            // Change the success message text here
            setSuccessMessage({ general: "Your loan application has been submitted successfully! Thank you!" });
            setIsModalOpen(true); // Open the modal on success
        }
    } catch (err) {
        // Check if err.response and err.response.data exist before accessing message
        if (err.response && err.response.data && err.response.data.message) {
            setFormErrors({ general: `${err.response.data.message}` });
        } else if (err.data && err.data.message) {
            setFormErrors({ general: `${err.data.message}` });
        } else {
            setFormErrors({ general: "Uhoh! Something went wrong. Please resubmit the form." }); // Fallback error message
        }
    }
  };

  useEffect(() => {
    // Fetch the user's IP address when the component is first rendered
    const fetchIP = async () => {
      const ip = await getUserIP();
      setFormData(prevData => ({ ...prevData, ipAddress: ip }));
    };

    fetchIP();
  }, []);

  return (
    <div className="pb-[124px] font-sans min-h-screen flex flex-col items-center bg-gray-100">
      <header className="text-center py-8">
        <h1 className="text-3xl font-bold text-gray-700"> Form</h1>
      </header>

      <div className="lg:w-[60%] sm:w-[100%] mx-auto p-6 bg-white shadow-md rounded-md">
        {/* Confirmation Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded-md shadow-md">
              <h2 className="text-lg font-bold">Confirmation</h2>
              <p>{successMessage.general}</p>
              <button
                onClick={() => {
                  setIsModalOpen(false);
                  window.location.href = "/"; // Navigate to homepage
                }}
                className="mt-4 py-2 px-4 bg-bardum text-white rounded-md"
              >
                Return to Home
              </button>
            </div>
          </div>
        )}

        <form className="w-full min-h-[400px]" id="loanForm " onSubmit={handleSubmit}>
          {Object.keys(formErrors).length > 0 && (
            <div className="mb-4 p-4 bg-red-100 text-bardum rounded-md">
              <p>{Object.values(formErrors)[0]}</p>
            </div>
          )}

          {Object.keys(successMessage).length > 0 && (
            <div className="mb-4 p-4 bg-green-400 text-white rounded-md">
              <p>{Object.values(successMessage)[0]}</p>
            </div>
          )}
          {currentSection === 0 && (
            <fieldset className="form-section border border-gray-500 p-8 rounded-[12px]">
              <legend className="text-lg text-gray-700 font-semibold mb-4 p-[10px]">
                Personal Information
              </legend>
              {/* Personal Info Fields */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label htmlFor="hearAbout" className="block mb-2">
                    How did you hear about us?
                  </label>
                  {isOtherSelected ? (
                    <input
                      type="text"
                      id="otherHearAbout"
                      name="otherHearAbout"
                      value={formData.otherHearAbout}
                      onChange={handleChange}
                      placeholder="Please specify"
                      className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                    />
                  ) : (
                    <select
                      id="hearAbout"
                      name="hearAbout"
                      value={formData.hearAbout}
                      onChange={handleChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                    >
                      <option value="Google">Google</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Twitter">Twitter</option>
                      <option value="Tiktok">Tiktok</option>
                      <option value="LinkedIN">LinkedIN</option>
                      <option value="Other">Other</option>
                    </select>
                  )}
                </div>
                <div>
                  <label htmlFor="financialSolution" className="block mb-2">
                    Financial Solutions
                  </label>
                  <select
                    id="financialSolution"
                    name="financialSolution"
                    value={formData.financialSolution}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  >
                    <option value="HomeEquity">Home Equity</option>
                    <option value="HomeRefinance">Home Refinance</option>
                    <option value="PersonalLoan">Personal Loan</option>
                    <option value="Insurance">Insurance</option>{" "}
                    <option value="BusinessLoan">Business Loan</option>{" "}
                    <option value="StudentLoan">Student Loan</option>
                    <option value="TaxRelief">Tax Relief</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="firstName" className="block mb-2">
                    First Name <span className="text-red-500">*</span> {/* Added asterisk */}
                  </label>
                  <input
                    type="text"
                    maxlength="50"
                    id="firstName"
                    name="firstName"
                    placeholder="Enter first name."
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  />
                </div>

                <div>
                  <label htmlFor="lastName" className="block mb-2">
                    Last Name <span className="text-red-500">*</span> {/* Added asterisk */}
                  </label>
                  <input
                    maxlength="50"
                    type="text"
                    placeholder="Enter last name."
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  />
                </div>
                <div>
                  <label className="block mb-2">
                    Email <span className="text-red-500">*</span> {/* Added asterisk */}
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter email."
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  />
                </div>
                <div>
                  <label htmlFor="dob" className="block mb-2">
                    Date of Birth <span className="text-red-500">*</span> {/* Added asterisk */}
                  </label>
                  <input
                    type="date"
                    id="dob"
                    placeholder="Enter date-of-birth."
                    name="dob"
                    value={formData.dob}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  />
                </div>
                <div>
                  <label htmlFor="ssn" className="block mb-2">
                    SSN
                  </label>
                  <input
                    maxlength="50"
                    type="text"
                    id="ssn"
                    placeholder="Enter SSN."
                    name="ssn"
                    value={formData.ssn}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  />
                </div>
                <div>
                  <label htmlFor="phoneHome" className="block mb-2">
                    Phone <span className="text-red-500">*</span> {/* Added asterisk */}
                  </label>
                  <input
                    maxlength="12"
                    type="text"
                    placeholder="Enter home phone number."
                    id="phoneHome"
                    name="phoneHome"
                    value={formData.phoneHome}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  />
                </div>
                <div>
                  <label htmlFor="address1" className="block mb-2">
                    Address
                  </label>
                  <input
                    maxLength="50"
                    type="text"
                    id="address1"
                    placeholder="Enter address."
                    name="address1"
                    value={formData.address1}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  />
                </div>
                <div>
                  <label htmlFor="zipCode" className="block mb-2">
                    Zip Code <span className="text-red-500">*</span> {/* Added asterisk */}
                  </label>
                  <input
                    placeholder="Enter zip code."
                    type="number"
                    maxlength="10"
                    id="zipCode"
                    name="zipCode"
                    value={formData.zipCode}
                    onChange={handleChange}
                    required
                    onInput={(e) => {
                      if (e.target.value.length > 50) {
                        e.target.value = e.target.value.slice(0, 50);
                      }
                    }}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  />
                </div>
                <div>
                  <label htmlFor="driversLicense" className="block mb-2">
                    Driver's License
                  </label>
                  <input
                    type="text"
                    id="driversLicense"
                    name="driversLicense"
                    placeholder="Enter driving license."
                    value={formData.driversLicense}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  />
                </div>
                <div>
                  <label htmlFor="driversLicenseState" className="block mb-2">
                    Driver's License State
                  </label>
                  <input
                    minlength="2"
                    maxlength="2"
                    type="text"
                    id="driversLicenseState"
                    placeholder="Enter driving license state."
                    name="driversLicenseState"
                    value={formData.driversLicenseState}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  />
                </div>
              </div>
            </fieldset>
          )}
          {/* Income Information Section */}
          {currentSection === 1 && (
            <fieldset className="form-section border border-gray-500 p-8 rounded-[10px]">
              <legend className="text-lg text-gray-700 font-semibold mb-4 p-[10px]">
                Income Information
              </legend>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {/* New fields start here */}
                <div>
                  <label htmlFor="loanPurpose" className="block mb-2">
                    Debt Loan Purpose (Optional)
                  </label>
                  <select
                    id="loanPurpose"
                    name="loanPurpose"
                    placeholder="Enter loan purpose."
                    value={formData.loanPurpose}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  >
                    <option value=""></option>
                    <option value="Debtconsolidation">
                      Debt Consolidation
                    </option>
                    <option value="Creditcard">Credit Card</option>
                    <option value="Homeimprovement">Home Improvement</option>
                    <option value="Studentloanconsolidation">
                      Student Loan Consolidation
                    </option>
                    <option value="Majorpurchase">Major Purchase</option>
                    <option value="Car">Car</option>
                    <option value="Greenloan">Green Loan</option>
                    <option value="Business">Business</option>
                    <option value="Vacation">Vacation</option>
                    <option value="Wedding">Wedding</option>
                    <option value="Relocation">Relocation</option>
                    <option value="Medical">Medical</option>
                    <option value="Household">Household</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="requestedAmount" className="block mb-2">
                    Requested Amount (Optional)
                  </label>
                  <input
                    type="number"
                    id="requestedAmount"
                    placeholder="Enter the requested amount."
                    name="requestedAmount"
                    value={formData.requestedAmount > 0 ? formData.requestedAmount : ''}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  />
                </div>
                <div>
                  <label htmlFor="credit" className="block mb-2">
                    Credit Score
                  </label>
                  <select
                    id="credit"
                    name="credit"
                    value={formData.credit}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  >
                    <option value="Excellent">Excellent</option>
                    <option value="Verygood">Very Good</option>
                    <option value="Good">Good</option>
                    <option value="Fair">Fair</option>
                    <option value="Poor">Poor</option>
                    <option value="Verypoor">Very Poor</option>
                    <option value="Unsure">Unsure</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="ownHome" className="block mb-2">
                    Own Home
                  </label>
                  <select
                    id="ownHome"
                    name="ownHome"
                    value={formData.ownHome}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  >
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="employerName" className="block mb-2">
                    Employer Name
                  </label>
                  <input
                    type="text"
                    placeholder="Enter employer name."
                    id="employerName"
                    name="employerName"
                    value={formData.employerName}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  />
                </div>
                <div>
                  <label htmlFor="monthsAtCompany" className="block mb-2">
                    Months at Company
                  </label>
                  <input
                    type="number"
                    placeholder="Enter number of months."
                    id="monthsAtCompany"
                    name="monthsAtCompany"
                    value={formData.monthsAtCompany > 0 ? formData.monthsAtCompany : ''}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  />
                </div>
                <div>
                  <label htmlFor="incomeType" className="block mb-2">
                    Income Type <span className="text-red-500">*</span> {/* Added asterisk */}
                  </label>
                  <select
                    id="incomeType"
                    name="incomeType"
                    value={formData.incomeType}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  >
                    <option value="employed">Employed</option>
                    <option value="selfemployed">Self-Employed</option>
                    <option value="military">Military</option>
                    <option value="benefits">Benefits</option>
                    <option value="retired">Retired</option>
                    <option value="unemployed">Unemployed</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="monthlyIncome" className="block mb-2">
                    Monthly Income <span className="text-red-500">*</span> {/* Added asterisk */}
                  </label>
                  <input
                    placeholder="Enter monthly income."
                    type="number"
                    id="monthlyIncome"
                    name="monthlyIncome"
                    value={formData.monthlyIncome > 0 ? formData.monthlyIncome : ''}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  />
                </div>
                <div>
                  <label htmlFor="payDate1" className="block mb-2">
                    Pay Date
                  </label>
                  <input
                    type="date"
                    placeholder="Enter pay date."
                    id="payDate1"
                    name="payDate1"
                    value={formData.payDate1}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  />
                </div>
                <div>
                  <label htmlFor="payFrequency" className="block mb-2">
                    Pay Frequency
                  </label>
                  <select
                    id="payFrequency"
                    name="payFrequency"
                    value={formData.payFrequency}
                    onChange={handleChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                  >
                    <option value="Weekly">Weekly</option>
                    <option value="Biweekly">Bi-Weekly</option>
                    <option value="Semimonthly">Semi-Monthly</option>
                    <option value="Monthly">Monthly</option>
                  </select>
                </div>
              </div>
            </fieldset>
          )}
          {/* payment details */}
          {currentSection === 2 && (
            <fieldset className="form-section border border-gray-500 p-8 rounded-[12px]">
              <legend className="text-lg text-gray-700 font-semibold mb-4 p-[10px]">
                Tell Us More
              </legend>
              <div className="grid grid-cols-1 gap-4">
                <div>
                  <label htmlFor="tellUsMore" className="block mb-2">
                    Have you experienced any personal or economic hardships recently? If so, you could qualify for additional aid at competitive rates! <span className="text-red-500">*</span> {/* Added asterisk */}
                  </label>
                  <textarea
                    id="tellUsMore"
                    name="tellUsMore"
                    placeholder="Please describe your situation."
                    value={formData.tellUsMore}
                    onChange={handleChange}
                    required
                    className="w-full px-3 py-2 border border-gray-300 rounded-md outline-none"
                    rows="4"
                  />
                </div>
              </div>
            </fieldset>
          )}
          {currentSection == 3 && <Summary formData={formData} />}

          <div className={`flex mt-6 justify-between m-b-0 ${currentSection > 3 && "mt-[400px]"}`}>
            <div className="flex justify-start">
              {currentSection > 0 && (
                <button
                  type="button"
                  onClick={handlePrev}
                  className="py-2 px-4 bg-gray-300 text-gray-700 rounded-md"
                >
                  Back
                </button>
              )}
            </div>
            {currentSection === 4 ? ( // Check if on the success/error page
                <button
                    onClick={() => (window.location.href = "/")} // Navigate to homepage
                    className="py-2 px-4 bg-bardum text-white rounded-md"
                >
                    Return to Home
                </button>
            ) : (
                <button
                    type="button" // Ensure this button does not submit the form
                    onClick={currentSection === 3 ? handleSubmit : (e) => { e.preventDefault(); handleNext(); }} // Submit on summary page, else handleNext
                    className="py-2 px-[60px] bg-transparent transition-all duration-300 ease-in-out text-bardum border hover:bg-bardum hover:text-white border-bardum font-semibold rounded-md"
                >
                    {currentSection === 3 ? "Submit" : "Next"}
                </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
export default LoanApplicationForm;