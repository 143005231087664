import React, { useEffect } from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";

const Footer = () => {
  useEffect(() => {
    const select = (el, all = false) => {
      el = el.trim();
      return all
        ? [...document.querySelectorAll(el)]
        : document.querySelector(el);
    };

    const backtotop = select(".back-to-top");
    const toggleBacktotop = () => {
      if (window.scrollY > 100) {
        backtotop?.classList.add("active");
      } else {
        backtotop?.classList.remove("active");
      }
    };

    window.addEventListener("scroll", toggleBacktotop);
    window.addEventListener("load", toggleBacktotop);

    return () => {
      window.removeEventListener("scroll", toggleBacktotop);
      window.removeEventListener("load", toggleBacktotop);
    };
  }, []);

  return (
    <footer className="bg-gradient-to-r sm:mt-16 from-slate-900 via-slate-700 to-slate-900 py-10 px-10 font-sans tracking-wide">
      <div className="max-w-2xl mx-auto text-center">
        <a href="javascript:void(0)" className="inline-block">
          <h2 style={{ fontSize: "30px", color: "white", fontWeight: "600" }}>
            Assurance Partners LLC
          </h2>
        </a>
        <p className="text-sm mt-8 text-gray-200">
                  Helping you become one step closer to achieving your financial goals
        </p>

        <ul className="flex items-center justify-center flex-wrap gap-y-3 gap-x-6 mt-8">
          <li>
            <a href="https://www.facebook.com/profile.php?id=61560233856672">
              <FaFacebook className="inline w-6 h-6 text-white" />
            </a>
          </li>

          <li>
            <a href="https://www.instagram.com/assurance_partners/">
              <FaInstagram className="inline w-6 h-6 text-white" />
            </a>
          </li>
        </ul>

        <p className="mt-10 text-gray-400 text-xs">
          © 2024 Assurance Partners LLC. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
